import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormAction } from '../../../../contexts/FormActionContext';
import FileService from '../../../../services/FileService';
import { FileInfo } from '../../../shared/file-upload/FileInfo';
import FileInfoCard from '../../../shared/file-upload/FileInfoCard';
import FileViewer from '../../../shared/file-upload/FileViewer';
import SingleFileModal from '../../../shared/file-upload/SingleFileModal';
import Button, { ButtonSize, ButtonType } from '../../../shared/form-control/Button';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';

type SingleFileUploadActionProps = ActionBaseProps<FileInfo>;

const SingleFileUploadAction: FC<SingleFileUploadActionProps> = (props) => {
  const { t } = useTranslation('activity-type');
  const { response, data, required } = props;
  const { onAnswer, readOnly } = useFormAction(props);

  const [internalFile, setInternalFile] = useState<FileInfo | null>(response || null);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewerOpen, setViewerOpen] = useState(false);

  const onInternalFileChanged = (f: FileInfo | null): void => {
    if (!f && internalFile) {
      const fileId = internalFile.id;
      FileService.deleteFile(fileId);
    }

    setInternalFile(f);
    onAnswer(f);
    onModalClose();
  };

  useEffect(() => {
    setInternalFile(response);
  }, [response]);

  const onModalClose = () => {
    setModalOpen(false);
  };

  const onViewerClose = () => {
    setViewerOpen(false);
  };

  return (
    <div data-cy="single-file-action">
      <ActionTitleDescription required={required} {...data} />

      {internalFile && (
        <FileInfoCard
          file={internalFile}
          onCancel={() => onInternalFileChanged(null)}
          viewOnly={readOnly}
          onFileEdit={() => {
            setModalOpen(true);
          }}
        />
      )}
      <SingleFileModal open={modalOpen} onSave={onInternalFileChanged} onCancel={onModalClose} file={internalFile} />
      {internalFile && <FileViewer open={viewerOpen} file={internalFile} onComplete={onViewerClose} />}

      {!internalFile && (
        <Button data-cy="upload-button" onClick={() => setModalOpen(true)} disabled={readOnly} size={ButtonSize.S} type={ButtonType.PRIMARY}>
          {t('file-upload.buttons.upload-doc')}
        </Button>
      )}
    </div>
  );
};

export default SingleFileUploadAction;
